/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
import { Input, Button, message } from 'antd';
import OtpInput from 'react-otp-input';
import { withRouter } from 'react-router-dom';
import TweenOne from 'rc-tween-one';
import { connect } from 'react-redux';
import Mixpanel from 'mixpanel-browser';
import Cookies from 'js-cookie';

import { adminEffects, otpEffects } from '../../@betterhalf-private/redux/src';

import './styles.less';
import Logo from '../../assets/logo.png';
import { setAuthHeaders } from '../../utilities/api';
import { isEmpty, isStagingEnv } from '../../utilities/util';
import DetailsCollapse from '../UserOnboarding/Details/DetailCollapse';
import Detailpanel from '../UserOnboarding/Details/DetailPanel';
import DetailHeader from '../UserOnboarding/Details/DetailHeader';
import { SelectPreference } from '../UserOnboarding/Commons';
import httpClient from '../../@betterhalf-private/api/client';

const STAGING_LIST = [
  {
    id: 1,
    displayText: 'Azure 1',
    value: 'https://azurestaging1.betterhalf.ai/v2',
  },
  {
    id: 2,
    displayText: 'Azure 2 ',
    value: 'https://azurestaging2.betterhalf.ai/v2',
  },
  {
    id: 3,
    displayText: 'Azure 3',
    value: 'https://azurestaging3.betterhalf.ai/v2',
  },
  {
    id: 4,
    displayText: 'Azure 4',
    value: 'https://azurestaging4.betterhalf.ai/v2',
  },
  {
    id: 5,
    displayText: 'Azure Pre Prod',
    value: 'https://betterhalfbackend.preprod.betterhalf.ai/v2',
  },
];

class Login extends Component {
  constructor(props) {
    super(props);
    this.inputAnimation = { duration: 500, opacity: 0 };
    this.otpAnimation = { duration: 800, delay: 200, opacity: 1 };
    this.state = {
      mobile: '',
      otp: '',
      isOtpSent: false,
      paused: true,
      msgShown: false,
      // isCollpaseActive: undefined,
      activeKey: undefined,
      selectedStaging: undefined,
    };
  }

  componentDidMount() {
    if (isStagingEnv()) {
      const savedStagingData = localStorage.getItem('bh__staging-data')
        ? JSON.parse(localStorage.getItem('bh__staging-data'))
        : STAGING_LIST[1];
      // saved if it is not saved yet
      localStorage.setItem(
        'bh__staging-data',
        JSON.stringify(savedStagingData)
      );

      // set the local state
      this.setState({
        selectedStaging: savedStagingData,
      });
    }
  }

  static getDerivedStateFromProps(props, state) {
    const {
      verifyOtpSuccess: verifySuccess,
      verifyOtpFailure: verifyFailure,
      history,
      getAdminProfileData,
      sendOtpSuccess,
    } = props;

    if (verifySuccess) {
      const {
        access_token: accessToken,
        refresh_token: refreshToken,
        token_type: tokenType,
      } = verifySuccess?.token || {};
      Cookies.set('accessToken', accessToken);
      Cookies.set('refreshToken', refreshToken);
      Cookies.set('tokenType', tokenType);
      window.client.setAuthToken(accessToken);
      setAuthHeaders();

      getAdminProfileData();
      message.success('Login Successful');
      history.replace('/home/search');
      Mixpanel.track('LOGIN_SUCCESSFUL');
    } else if (verifyFailure) {
      message.error(`Please check the OTP you entered is incorrect!`);
    } else if (sendOtpSuccess && !state.msgShown) {
      message.success(`OTP sent successfully to +91 ${state.mobile}`);
      return {
        msgShown: true,
      };
    }
    return {};
  }

  componentDidUpdate() {
    const { otp } = this.state;
    if (otp.length === 4) {
      this.handleOtpLogin();
    }
  }

  handleSelectStaging = (obj) => {
    // save in local storage
    localStorage.setItem('bh__staging-data', JSON.stringify(obj));

    this.setState({
      selectedStaging: obj,
      activeKey: undefined,
    });
  };

  handleCollapseChange = (key) => {
    this.setState({
      activeKey: key,
    });
  };

  handleOtpChange = (otp) => {
    this.setState({
      otp,
    });
  };

  handleMbNoChange = (e) => {
    this.setState({
      mobile: e.target.value,
    });
  };

  handleOtpLogin = async () => {
    const { isOtpSent, mobile, otp } = this.state;
    const { sendOTP, verifyOTP } = this.props;

    if (!isOtpSent) {
      const data = {
        mobile,
        isd_code: '+91',
        medium: 'SMS',
      };
      sendOTP({ data });
      Mixpanel.track('OTP_SENT', {
        data,
      });
      this.setState({
        isOtpSent: true,
        paused: false,
      });
      return null;
    }

    const data = {
      mobile,
      isd_code: '+91',
      otp,
    };

    verifyOTP(data);
    Mixpanel.track('OTP_VERIFIED', {
      data,
    });
    const accessToken = Cookies.get('accessToken');

    if (accessToken) {
      const tokenType = Cookies.get('tokenType') || '';
      httpClient.defaults.headers.common.Authorization = `${tokenType} ${accessToken}`;
    }

    return null;
  };

  requestOtp = () => {};

  handleSubmit = (e) => {
    e.preventDefault();
    this.handleOtpLogin();
  };

  render() {
    const { mobile, otp, isOtpSent, paused } = this.state;
    const { loadingStatus } = this.props;
    return (
      <div className="HeroSection">
        <div className="c-container">
          <div>
            <img alt="" src={Logo} className="logo" />
            {isStagingEnv() && (
              <div className="login__collapse__container">
                <DetailsCollapse
                  destroyInactivePanel
                  activeKey={this.state.activeKey}
                  onChange={this.handleCollapseChange}
                >
                  <Detailpanel
                    header={
                      <DetailHeader
                        headerTitle="Staging Endpoint"
                        value={
                          this.state.selectedStaging &&
                          this.state.selectedStaging.displayText
                        }
                        isActive={this.state.activeKey === 'current_location'}
                      />
                    }
                    key="current_location"
                  >
                    <div>
                      <SelectPreference
                        list={STAGING_LIST}
                        selectedPreference={this.state.selectedStaging}
                        handlePreference={this.handleSelectStaging}
                      />
                    </div>
                  </Detailpanel>
                </DetailsCollapse>
              </div>
            )}
          </div>

          <div style={{ margin: '10px' }}>
            <h1 className="c-heading">Admin Panel</h1>
          </div>
          <form onSubmit={(e) => this.handleSubmit(e)}>
            {!isOtpSent && (
              <TweenOne
                style={{ margin: '10px' }}
                animation={this.inputAnimation}
                paused={paused}
              >
                <Input
                  size="large"
                  className="phone-input"
                  allowClear
                  value={mobile}
                  placeholder="Enter Mobile No."
                  onChange={this.handleMbNoChange}
                  disabled={loadingStatus}
                  onPressEnter={this.requestOtp}
                />
              </TweenOne>
            )}
            {isOtpSent && (
              <TweenOne
                style={{
                  margin: '10px',
                  opacity: 0,
                }}
                animation={this.otpAnimation}
                paused={paused}
              >
                <OtpInput
                  shouldAutoFocus
                  containerStyle="otp-container"
                  inputStyle="otp-input"
                  focusStyle="otp-focus"
                  value={otp}
                  onChange={this.handleOtpChange}
                  separator={<span className="separator">-</span>}
                />
              </TweenOne>
            )}
            <div style={{ margin: '10px' }}>
              <Button
                type="submit"
                className="btn"
                onClick={(e) => this.handleSubmit(e)}
              >
                {isOtpSent ? `Verify OTP` : `Login`}
              </Button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  adminData: state.adminData.data,
  loadingStatus: state.adminData.isLoading,
  sendOtpSuccess: state.sendOtpData.success,
  verifyOtpSuccess: state.verifyOtpData.accessToken,
  verifyOtpFailure: !isEmpty(state.verifyOtpData.error),
  verifyOtpData: state.verifyOtpData.data,
});

export default connect(mapStateToProps, {
  verifyOTP: otpEffects.verifyOTP,
  sendOTP: otpEffects.sendOtp,
  getAdminProfileData: adminEffects.getAdminProfileData,
})(withRouter(Login));
